import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
export const whatJSCanLearnFromPythonQuery = graphql`
  query WhatJSCanLearnFromPythonQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  whatJSCanLearnFromPythonQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`I recently started working at a company with a legacy system written in Python, which has also been my first introduction to Python as a programming language. Coming from the JavaScript/TypeScript world, there has been a few interesting language design decisions that Python has that I wish JavaScript had as well.`}</p>
    <blockquote>
      <p parentName="blockquote">{`I am only starting my Python discovery journey now, so I will most likely update this post as I find new gems.`}</p>
    </blockquote>
    <h2>{`The `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`sum()`}</code>{` function`}</h2>
    <p>{`Python has a bunch of global functions that are available anywhere within the source code. One of these is the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sum()`}</code>{` function.`}</p>
    <p>{`This function allows you to quickly calculate the total of a list of values (there are other use-cases as well):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`listOfNumbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`75`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`434`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`234`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8472`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

total `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`listOfNumbers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`total`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# prints out: 9338`}</span></code></pre></div>
    <p>{`In JavaScript, you could implement your own `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sum()`}</code>{` function with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`sum`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`list`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` startValue `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`0`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`acc`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` item`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`acc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+=`}</span>{` item`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` startValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`This is not a lot of code to write yourself, but it is nice that Python gives it to you for free.`}</p>
    <h2>{`The `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`sorted()`}</code>{` function`}</h2>
    <p>{`Like JavaScript, Python also has a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.sort()`}</code>{` method that you can invoke on a list and like JavaScript, this will mutate the list/array in place. Mutating values has its place, but most often it is best to avoid them to make your code more predictable.`}</p>
    <p>{`Python gives you the global `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sorted()`}</code>{` function that solves this nicely, ie. you can sorted a given list without mutating the original list. This function doesn't only work on Python lists, but can also be used to sort tuples and dictionaries (objects or maps).`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`unsortedList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"a"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"d"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"c"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"b"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

sortedList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`sorted`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`unsortedList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`sortedList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# prints out: ['a', 'b', 'c', 'd']`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`unsortedList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# prints out: ['a', 'd', 'c', 'b']`}</span></code></pre></div>
    <p>{`To create a new list sorted in descending order, you can simply pass a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`reverse=True`}</code>{` keyword argument (the Pythonians call these `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`kwargs`}</code>{` which is kwite kwool):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`sortedList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`sorted`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`unsortedList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` reverse`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`True`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`JavaScript doesn't have this, so the easiest is probably to do something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`sorted`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`list`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` reverse `}<span parentName="span" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="span" {...{
              "className": "token boolean"
            }}>{`false`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` clonedList `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` list`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`slice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  clonedList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sort`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reverse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    clonedList`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reverse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` clonedList
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`I don't know how Python does it internally, so hopefully a bit more performant than the JavaScript example I gave above. 🤷‍♂️`}</p>
    <p>{`There is more to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sorted()`}</code>{` function, but the main point I wanted to bring is the immutability of it.`}</p>
    <h2>{`Object comparison`}</h2>
    <p>{`Python does not have the strict equality (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`===`}</code>{`) and loose equality (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`==`}</code>{`) concept that you get in JavaScript. Python uses `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`==`}</code>{` to compare values for all types. In a way it behaves like JavaScript's strict equality, but not exactly.`}</p>
    <p>{`For instance, take `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"2" == 2`}</code>{`:`}</p>
    <p>{`In JavaScript, that will evaluate to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` since the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{` integer will be cast to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"2"`}</code>{` string and it will be equal.
In Python, it will evaluate to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`False`}</code>{` because nothing will be cast, you need to be explicit with your types. To get this expression to evaluate to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`True`}</code>{`, you would need to explicitly cast one of the values to the other's type: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`int("2") == 2`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`"2" == str(2)`}</code>{`.`}</p>
    <p>{`Up until here, it is debateable which behaviour is better. I'm someone that loves JavaScript for what it is, so I do embrace loose equality where it makes sense to do, but some people feel very strong about only using strict equality and even implement ESLint rules to enforce this. To each their own.`}</p>
    <p>{`Where Python does better is when it comes to comparing objects. In JavaScript, objects (of all kinds), are compared by reference, which simply means that it compares the address of where the value is stored in memory instead of what is stored in the memory slot.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` objectA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'value'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` objectB `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'value'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` objectB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` objectB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Both of these comparisons will evaluate to \`false\` since they are different objects`}</span></code></pre></div>
    <p>{`Python actually compares the values in the object to determine if it is equal:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`objectA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"value"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
objectB `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"value"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` objectB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This comparison will evaluate to \`True\` because the values inside each object are the same`}</span></code></pre></div>
    <p>{`If you wanted to compare whether two objects are in fact the same object stored at the same address in memory, Python has you covered with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`is`}</code>{` operator:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`objectA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"value"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
objectB `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"value"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectA `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`is`}</span>{` objectB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This comparison will evaluate to \`False\` because these are not the same objects`}</span>{`

objectC `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` objectA

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectA `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`is`}</span>{` objectC`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This comparison will evaluate to \`True\` because these are the same objects in memory`}</span></code></pre></div>
    <p>{`This leads me to my next point, which is more a nice to have and probably not all that useful in practice, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`id()`}</code>{` global function.`}</p>
    <h2>{`The `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`id()`}</code>{` function`}</h2>
    <p>{`Each value (in any language really) is stored somewhere in the device's memory. Python makes it easy to find this reference or ID by using the global `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`id()`}</code>{` function.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`someNumber `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`123`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`someNumber`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# prints out the reference number for the given value (4326821360 in my case, but this will be different for you)`}</span></code></pre></div>
    <p>{`This is essentially how Python's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`is`}</code>{` operator works as well. Taking the example from my previous point, it could be compared this way as well:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`objectA `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"key"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"value"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
objectB `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` objectA

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectA `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`is`}</span>{` objectB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectA`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`objectB`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# These comparisons are equivalent and both will return \`True\` as they are the same objects`}</span></code></pre></div>
    <h2>{`The `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`object.get()`}</code>{` method`}</h2>
    <p>{`In JavaScript, if you try and read a non-existent property from a object with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`object['someNonExistingKey']`}</code>{`, you will simply get back `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`undefined`}</code>{`. Python doesn't do that, it throws an error. That doesn't sound better, but it is because it ensures you are more explicit about the data structures you're working with.`}</p>
    <p>{`Python has a built-in method on objects called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.get()`}</code>{`, which is basically the equivalent of Lodash's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`_.get()`}</code>{` method in JavaScript:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`person `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Adam"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` person`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Eve"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# prints out: 'Adam'`}</span>{`

age `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` person`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`get`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"age"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# prints out: 25`}</span></code></pre></div>
    <p>{`This method will not throw an error when trying to access a property (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`age`}</code>{`) from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`person`}</code>{` object that doesn't exist, but will use the optional default values given as a second parameter as a fallback. Or alternatively, it will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`None`}</code>{`.`}</p>
    <h2>{`No `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`var`}</code>{`, `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`let`}</code>{` or `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`const`}</code>{` debates`}</h2>
    <p>{`TODO: Write this piece 🚢`}</p>
    <hr />
    <p><em parentName="p">{`If you have any other great Python features that you wished JavaScript had, please share them so I can also learn from you.`}</em></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      